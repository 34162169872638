<template>
	<div class="orderinfo">
		<div class="tofilm-top">
			<div class="tofilm-title">
				<div class="title">实拍订单号：{{orderInfoData.order_num}}</div>
				<div class="anniu"><el-button type="primary" @click="tofilmList">返回订单列表</el-button></div>
			</div>
			<div class="tofilm-prompt">
				<!-- <div class="text">实拍下单工作时间：周一到周五9:30 - 12:30  14:00 - 18:30</div> -->
			</div>
		</div>
		<div class="orderinfo-bottom">
			<div class="dan">
				<div class="title">下单时间：</div>
				<div class="text">{{orderInfoData.updated_at}}</div>
			</div>
			<div class="dan">
				<div class="title">订单状态：</div>
				<div class="text">{{orderInfoData.status == 1 ? '未付款' : orderInfoData.status == 2 ? '拍摄中' : orderInfoData.status == 3 ? '拍摄中' : orderInfoData.status == 4 ? '已完成' : orderInfoData.status == 5 ? '已退款' : orderInfoData.status == 6 ? '订单失效' : ''}}</div>
				<div class="text" v-if="orderInfoData.payment_method == 2 && orderInfoData.status == 1" ><el-tag type="danger" effect="dark" @click="onClickByIntegral()">积分支付</el-tag></div>
				<div class="text" v-else><el-tag v-if="orderInfoData.status == 1" type="danger" effect="dark" @click="onpayorder(orderInfoData)">付款</el-tag></div>
				<div class="text" v-if="orderInfoData.status == 4" ><el-button type="primary" @click="onMobTheShow">提交反馈</el-button></div>
			</div>
			<div class="dan">
				<div class="title">订单金额：</div>
				<div class="text" style="color:#67C23A" v-if="orderInfoData.payment_method == 2">{{orderInfoData.integral}}积分</div>
				<div class="text" style="color:#f00" v-else>￥{{orderInfoData.cost}}</div>
			</div>
			<div class="dan">
				<div class="title">产品类型：</div>
				<div class="text">{{orderInfoData.classify_child}}</div>
			</div>
			<div class="dan">
				<div class="title">拍摄类型：</div>
				<div class="text">{{orderInfoData.shoot_type}}</div>
			</div>
			<div class="dan">
				<div class="title">秒数：</div>
				<div class="text">{{orderInfoData.second_num}}秒</div>
			</div>
			<div class="dan">
				<div class="title">镜头数：</div>
				<div class="text">{{orderInfoData.shot_num}}个</div>
			</div>
			<div class="dan">
				<div class="title">配音师：</div>
				<div class="text">{{orderInfoData.mixer}}</div>
			</div>
			<div class="dan">
				<div class="title">实拍占比：</div>
				<div class="text">{{orderInfoData.will_share}}</div>
			</div>
			
			<div class="dan">
				<div class="title">是否遮盖商标：</div>
				<div class="text">{{orderInfoData.is_brand}}</div>
			</div>
			<div class="dan">
				<div class="title">是否加水印：</div>
				<div class="text">{{orderInfoData.is_watermark}}</div>
			</div>
			<div class="dan">
				<div class="title">视频格式：</div>
				<div class="text">{{orderInfoData.model_format}}</div>
			</div>

			<div class="dan">
				<div class="title">产品链接：</div>
				<div class="text">{{orderInfoData.product_link}}</div>
			</div>
			<div class="dan">
				<div class="title">样品寄回：</div>
				<div class="text">{{orderInfoData.address ? orderInfoData.address : '不需要寄回'}}</div>
			</div>
			<div class="dan">
				<div class="title">邮箱地址：</div>
				<div class="text">{{orderInfoData.email}}</div>
			</div>
			<div class="dan">
				<div class="title">文案：</div>
				<div class="text">{{orderInfoData.content}}</div>
			</div>
			<div class="dan">
				<div class="title">视频卖点：</div>
				<div class="text">{{orderInfoData.selling_points}}</div>
			</div>
			
			<div class="dan">
				<div class="title">视频要求：</div>
				<div class="text">{{orderInfoData.ask}}</div>
			</div>
			<div class="dan">
				<div class="title">备注：</div>
				<div class="text">{{orderInfoData.priceandactivity_info}}</div>
			</div>
		</div>
		<el-dialog title="提交反馈" :visible.sync="ModThedialog" width="700px" custom-class="pyinfoclass">
			<div class="gongdan">
				<div class="dan">
					<div class="text"><span>*</span>是否过审：</div>
					<div class="inputbox">
						<el-radio v-model="gsRadio" label="1">已过审</el-radio>
  						<el-radio v-model="gsRadio" label="2">未过审</el-radio>
					</div>
				</div>
				<div class="dan">
					<div class="text"><span>*</span>未过审原因：</div>
					<div class="inputbox">
						<el-input type="textarea" :rows="2" placeholder="请输入内容" v-model="Opinion"></el-input>
					</div>
				</div>
				<div class="enderQueren">
					<el-button type="primary" @click="onTijiao">确认提交</el-button>
				</div>
			</div>
		</el-dialog>
		<Payorder ref="Payorder"></Payorder>
	</div>
</template>

<script>
import Payorder from './payorder.vue'
export default {
	data () {
		return {
			orderInfoData:{},//详细信息
			ModThedialog:false,
			gsRadio:'1',
			Opinion:'',//配音修改意见
		}
	},
	components:{
		Payorder,
	},
	mounted(){
		this.onorderinfo()
	},

	methods: {
		
		// 调取详细信息接口
		onorderinfo(){
			var param = {
				order_num : this.$route.query.order_num
			}
			this.$service.get(this.$api.getVideoOrderInfo, param, (res)=> {
				if(res.code == '200'){
					this.orderInfoData = res.data
				}
			})
		},
		//跳转列表
		tofilmList(){
			this.$router.push('/tofilm')
		},
		//调取支付
		onpayorder(row){
			if(this._isMobile){
				this.onClickJsapi()
			}else{
				this.$refs.Payorder.onOrdertext('实拍')
				this.$refs.Payorder.enterRouter('/tofilm')
				this.$refs.Payorder.onImgmorny(row.img,row.cost,row.order_num,row.time_expire)
			}
		},
		// 移动端支付点击
		onClickJsapi(){
			if(this._isMobile && this._isMicrom){
				alert('请用微信打开，否则不能在线支付')
			}
			else{
				this.onJsapiZhifu()
			}
		},
		// jsapi移动端支付接口参数收取
		onJsapiZhifu(){
			var param = {
				order_id:this.$route.query.order_num,
			}
			this.$service.post(this.$api.videoOrderPayByJsapi,param, (res)=> {
				if(res.code == '200'){
					this.$refs.Payorder.onBridgeReady(res.data.data)
					this.$refs.Payorder.onOrdertext('实拍')
					this.$refs.Payorder.enterRouter('/tofilm')
				}
			})
		},
		//积分支付
		onClickByIntegral(){
			var param = {
				order_num:this.$route.query.order_num,
			}
			this.$service.post(this.$api.pcVideoOrderPayByIntegral,param, (res)=> {
				if(res.code == '200'){
					this.$refs.Payorder.onSuccessPay()
					this.$refs.Payorder.enterRouter('/tofilm')
					this.$refs.Payorder.onOrdertext('实拍')
				}
			})
		},
		//提交反馈
		onMobTheShow(){
			this.ModThedialog = true
		},
		//提交反馈
		onTijiao(){
			if(this.gsRadio == '2' && this.Opinion == ''){this.$message({message: '请填写未过审原因', type: 'warning'}); return}
			var param = {
				id:this.orderInfoData.id,
				status:this.gsRadio,
				feedback_info:this.Opinion,
			}
			this.$service.post(this.$api.updateVideoFeedback,param, (res)=> {
				if(res.code == '200'){
					this.$message({message: '提交完成', type: 'success'})
					this.ModThedialog = false
					this.gsRadio = '1'
					this.Opinion = ''
					this.onorderinfo()
				}
			})
		},
	}
}
</script>

<style lang="scss" scoped>
.orderinfo{
	.tofilm-top{
		border-bottom: 1px solid #ededed;
		padding-bottom: 5px;
		.tofilm-title{
			display: flex;
			font-weight: 500;
			padding-bottom: 15px;
			.title{
				font-size: 22px;
			}
			.anniu{
				margin-left: 10px;
			}
		}
		.tofilm-prompt{
			display: flex;
			justify-content: space-between;
			.horn{
				padding: 5px 10px;
				background: #fefae9;
				color: #ff9a56;
			}
		}
	}
	.orderinfo-bottom{
		margin-top: 20px;
		margin-top: 20px;
		display: flex;
		flex-wrap: wrap;
		.dan{
			display: flex;
			padding-right: 20px;
    		box-sizing: border-box;
			// height: 40px;
			align-items: center;
			width: 33.33%;
			border-bottom: 1px solid #f9f9f9;
			padding-top: 15px ;
			padding-bottom: 15px ;
			.title{
				font-size: 16px;
				color: #333;
				font-weight: 500;
				min-width: 100px;
			}
			.text{
				color: #666;
				font-size: 16px;
				&:nth-child(3){
					margin-left: 10px;
				}
			}
		}
	}
}
@media screen and (max-width: 750px) {
	.orderinfo{
		.tofilm-top{
			.tofilm-prompt{
				display: block;
				.text{
					margin-bottom: 10px;
				}
				
			}
		}
		.orderinfo-bottom{
			display: block;
			.dan{
				width: 100%;
			}
		}
	}
}
</style>
<style lang="scss">
.pyinfoclass{
	.gongdan{
		.enderQueren{
			padding:0;
			padding-top: 10px;
		}
	}
}
</style>
